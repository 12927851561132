<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <h2>Support</h2>

      <v-spacer></v-spacer>

      <v-btn class="primary mr-4" @click="addSupportModal = true">
        <v-icon class="mr-2">{{ icons.addSupport }}</v-icon>
        Add Support
      </v-btn>
    </v-app-bar>

    <div
      class="mt-6 mx-4"
      id="user-list"
      v-infinite-scroll="loadMore"
      :infinite-scroll-disabled="loading"
      infinite-scroll-distance="100"
    >
      <v-simple-table>
        <thead>
          <tr>
            <th width="20%" colspan="2" class="text-left pa-0">Title</th>
            <th width="20%" class="text-left">Content</th>
            <th width="20%" class="text-left">Type</th>
            <th width="20%" class="text-left">Added date</th>
            <th width="20%" class="text-left">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="support in supports" :key="support.id" class="clickable">
            <td colspan="2">
              {{ support.title }}
            </td>
            <td>{{ support.content }}</td>
            <td>
              {{ support.type }}
            </td>
            <td>{{ support.addedDate }}</td>
            <td>
              <v-btn
                small
                class="primary mr-2"
                icon
                :disabled="loading"
                @click="
                  addSupportModal = true
                  isEditing = true
                  selectedItem = support
                "
              >
                <v-icon small color="white">{{ icons.edit }}</v-icon>
              </v-btn>
              <v-btn
                small
                class="error"
                icon
                :disabled="loading"
                @click="
                  selectedItem = support
                  deleteDialog = true
                "
              >
                <v-icon small color="white">{{ icons.delete }}</v-icon>
              </v-btn>
            </td>
          </tr>

          <template v-if="loading">
            <tr v-for="index in 10" :key="index + '-skeleton'">
              <td class="py-3 pl-0" width="5%">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
            </tr>
          </template>
        </tbody>
      </v-simple-table>

      <div
        class="d-flex justify-center mt-3"
        v-if="!(supports.length > 0) && !loading"
      >
        <p>No match found.</p>
      </div>
    </div>

    <v-snackbar v-model="snackbar.show" :color="snackbar.color" right>
      {{ snackbar.message }}
      <v-btn color="white" text @click="snackbar.show = false">Ok</v-btn>
    </v-snackbar>

    <v-dialog v-model="addSupportModal" persistent max-width="400px">
      <v-form v-model="isValidForm" ref="form" @submit.prevent="handleSupport">
        <v-card>
          <v-card-title>
            <span class="text-h5"
              >{{ isEditing ? 'Edit' : 'Add' }} Support</span
            >
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="form.title"
                    label="Title"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="form.content"
                    label="Content"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select
                    v-model="form.type"
                    :items="supportTypes"
                    label="Type"
                    :rules="[rules.required]"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="addSupportModal = false">
              Close
            </v-btn>
            <v-btn
              class="primary"
              :loading="loading"
              text
              @click="handleSupport"
            >
              {{ isEditing ? 'Update' : 'Save' }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog v-model="deleteDialog" max-width="290">
      <v-card>
        <v-card-title class="headline">Delete?</v-card-title>

        <v-card-text
          >Are you sure you want to delete this support content?</v-card-text
        >

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text @click="deleteDialog = false">Cancel</v-btn>

          <v-btn
            color="red"
            text
            @click="handleDeleteSupport"
            :loading="loading"
            >Yes, Delete please</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import {
  mdiDotsVertical,
  mdiUnfoldMoreHorizontal,
  mdiMagnify,
  mdiAccountCircleOutline,
  mdiChevronRight,
  mdiTrashCan,
  mdiPencil,
  mdiArrowUp,
  mdiArrowDown,
  mdiPlusCircle,
} from '@mdi/js'
import ControlsMixin from '@/utils/mixins/Controls'
import infiniteScroll from 'vue-infinite-scroll'
import debounce from 'lodash/debounce'

export default {
  name: 'SupportPage',

  mixins: [ControlsMixin],

  directives: {
    infiniteScroll,
  },

  data() {
    return {
      selectedItem: {},
      isValidForm: false,
      addSupportModal: false,
      isEditing: false,
      deleteDialog: false,
      loading: false,
      showSearch: false,
      showProfile: false,
      selectedProfile: null,
      filter: {
        search: '',
      },
      rules: {
        required: (value) => !!value || 'Required Field.',
      },
      icons: {
        delete: mdiTrashCan,
        edit: mdiPencil,
        settings: mdiDotsVertical,
        sort: mdiUnfoldMoreHorizontal,
        search: mdiMagnify,
        view: mdiAccountCircleOutline,
        arrow: mdiChevronRight,
        addSupport: mdiPlusCircle,
        arrowUp: mdiArrowUp,
        arrowDown: mdiArrowDown,
      },
      snackbar: {
        show: false,
        message: null,
        color: null,
      },
      form: {
        title: '',
        type: '',
        content: '',
      },
      defaultFilter: 'first_name',
      sortOrder: true,
      filterBy: [
        {
          value: 'title',
          label: 'Title',
        },
        {
          value: 'content',
          label: 'Content',
        },
        {
          value: 'type',
          label: 'Type',
        },
        {
          value: 'created_at',
          label: 'Added date',
        },
      ],
    }
  },

  watch: {
    selectedItem(value) {
      if (value) {
        this.form = { ...value }
      }
    },
  },

  async created() {
    await this.clearSupportList()
    this.fetchSupport(1)
  },

  computed: {
    ...mapState({
      supports: (state) => state.support.list,
      listMeta: (state) => state.support.listMeta,
    }),

    paginationLength() {
      return (this.listMeta.total / this.listMeta.per_page) | 0
    },
    supportTypes() {
      return [
        { text: 'Email', value: 'email' },
        { text: 'Link', value: 'link' },
        { text: 'Phone', value: 'phone' },
      ]
    },
  },

  methods: {
    ...mapActions('support', {
      getSupport: 'getSupport',
      addSupport: 'addSupport',
      updateSupport: 'updateSupport',
      deleteSupport: 'deleteSupport',
    }),

    ...mapMutations({
      clearSupportList: 'support/clearSupportList',
    }),

    pageChanged(page) {
      this.fetchSupport(page)
    },

    filterUser(filterValue) {
      this.clearUsers()
      this.fetchSupport(null, filterValue)
    },

    loadMore() {
      if (this.listMeta.current_page < this.listMeta.last_page) {
        this.fetchSupport(this.listMeta.current_page + 1)
      }
    },
    async handleSupport() {
      this.$refs.form.validate()
      if (this.isValidForm) {
        try {
          this.loading = true
          if (this.isEditing) {
            await this.updateSupport({ form: this.form, id: this.form.id })
          } else {
            await this.addSupport(this.form)
          }
          this.form = {
            title: '',
            type: '',
            content: '',
          }

          this.$refs.form.reset()
          this.loading = false
          this.addSupportModal = false
          this.showSnackbar(
            `Support content has been ${this.isEditing ? 'updated' : 'added'}`,
            'success'
          )
          await this.clearSupportList()
          await this.fetchSupport()
          this.isEditing = false
        } catch (error) {
          this.loading = false
        }
      }
    },

    search: debounce(function () {
      this.clearUsers()
      this.fetchSupport()
    }, 600),

    async fetchSupport(page = 1) {
      if (this.loading) return
      let params = {
        page,
        sort: this.sortOrder
          ? `${this.defaultFilter}`
          : `-${this.defaultFilter}`,
      }
      if (this.filter.search) {
        params.search = this.filter.search
      }
      this.loading = true
      await this.getSupport(params)
      this.loading = false
    },

    showProfileDialog(profile) {
      this.selectedProfile = profile
      this.showProfile = true
    },

    async exportList() {
      this.loading = true
      await this.exportToExcel()
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            })
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'users_list.xlsx')
          document.body.appendChild(link)
          link.click()
          this.showSnackbar('File successfully downloaded', 'success')
        })
        .catch(() => {
          this.showSnackbar('Ops! Something went wrong', 'red')
        })
      this.loading = false
    },
    async handleDeleteSupport() {
      this.loading = true
      try {
        await this.deleteSupport(this.selectedItem.id)
        this.showSnackbar('Support has been deleted.', 'success')
        this.loading = false
        await this.clearSupportList()
        await this.fetchSupport()
        this.deleteDialog = false
      } catch (error) {
        this.showSnackbar('Ops! Something went wrong', 'red')
        this.loading = false
      }
    },

    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.color = color
      this.snackbar.show = true
    },
    async showSearchField() {
      this.showSearch = !this.showSearch
      await this.$nextTick()
      this.$refs.searchField.$refs.input.focus()
    },
  },
}
</script>
